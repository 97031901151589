/* eslint-env jquery */
( $ => {
  $.fn.parallaxMove = function ( options ) {
    const settings = $.extend(
      {
        offset: 0,
        mobile: false,
        dir: 'top',
        headerFixed: false,
        twoWay: false,
        parentEl: 'section',
      },
      options
    );
    const currEl = this,
      wH = $( window ).height(),
      wW = $( window ).width(),
      hH = $( 'header' ).height(),
      parent = currEl.parents( `${settings.parentEl}` ),
      boundingBox = parent[0].getBoundingClientRect(),
      parentTop = boundingBox.top,
      parentBottom = boundingBox.bottom,
      speed = settings.headerFixed ? settings.offset / ( wH - hH ) : settings.offset / wH,
      inViewHeight = wH - parentTop,
      checkTwoWay = !settings.twoWay ? parentTop >= 100 : parentTop >= boundingBox.height * -1,
      scrollPortion =
        settings.dir === 'top' || settings.dir === 'right'
          ? settings.offset - inViewHeight * speed
          : ( settings.offset - inViewHeight * speed ) * -1,
      disableParallax = wW < 768 && !settings.mobile ? true : false;
    if ( parentTop <= parseInt( wH + 100 ) && parentBottom >= 0 && checkTwoWay && !disableParallax ) {
      if ( settings.dir === 'top' ) {
        currEl
          .css( {
            transform: 'translate3d(0,' + scrollPortion + 'px,0)',
          } )
          .addClass( 'parallax-init' );
      } else if ( settings.dir === 'right' ) {
        currEl
          .css( {
            transform: 'translate3d(' + scrollPortion + 'px, 0, 0)',
          } )
          .addClass( 'parallax-init' );
      }
    } else if ( !settings.twoWay && parentTop <= 0 ) {
      currEl.not( '.parallax-init' ).css( {
        transform: 'translate3d(0, 0, 0)',
      } );
    } else if ( disableParallax ) {
      currEl[0].style.removeProperty( 'transform', '-webkit-transform' );
    }
    currEl.addClass( 'parallax-item' );
  };
} )( jQuery );

$( window ).on( 'load scroll resize', () => {

  if ( $( '.bs-section--common-vertical-on-scroll' ).length ) {
    $( '.bs-div--common-vertical-onscroll-odd' ).each( ( index, element ) => {
      $( element ).eq( 0 ).parallaxMove( {
        mobile: true,
        offset: -200,
        twoWay: true,
        headerFixed: true,
      } );
    } );
    $( '.bs-div--common-vertical-onscroll-even' ).each( ( index, element ) => {
      $( element ).eq( 0 ).parallaxMove( {
        mobile: true,
        offset: 200,
        twoWay: true,
        headerFixed: true,
      } );
    } );
  }
} );
