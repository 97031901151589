const verticalLogoSlider = () => {
  const rows = document.querySelectorAll( '.bs-column--industry-inner-logo-slider > .bs-div' );
  if( !rows[0] ) return;
  
  const section = rows[0]?.closest( 'section' );

  /**
   * Check section is in viewport
   * @returns {boolean}
   */
  const inViewport = () => {
    const sectionRect = section.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    return sectionRect.top <= windowHeight && sectionRect.bottom >= 0;
  };

  /**
   * Handle logo slider movement
   * @returns {void}
   */
  const handleSlide = () => {
    if ( inViewport() ) {
      let scrollY = section.getBoundingClientRect().top / 5;

      if ( window.innerHeight > 1800 ) {
        scrollY -= 100;
      }

      if ( scrollY <= 1 ) {
        rows.forEach( ( row ) => { row.style.transform = 'translateY(0)'; } );
        return;
      }

      rows[0].style.transform = `translateY(${scrollY}px)`;
      rows[2].style.transform = `translateY(${-scrollY}px)`;
    }
  };

  window.addEventListener( 'scroll', handleSlide );
  handleSlide();
};

document.addEventListener( 'DOMContentLoaded', verticalLogoSlider );
