( () => {

  $( '.bs-div--pgb-code-wrapper-div .bs-pro-button--copy-text' ).click( async function () {
    const copyText = $( '.bs-div--security-copy-text-div p' ).text();
    const divContainer = $( '.bs-div--security-copy-text-div .bs-div__inner' );
      
    divContainer.css( 'overflow', 'visible' );
    $( this ).addClass( 'active' );
      
    try {
      await navigator.clipboard.writeText( copyText );
      setTimeout( function () {
        $( '.bs-pro-button--copy-text' ).removeClass( 'active' );
        divContainer.css( 'overflow', 'hidden' );
      }, 2500 );
    } catch ( err ) {
      console.error( 'Failed to copy text: ', err );
    }
  } );

  $( '.heateor_sss_button_copy_link' ).on( 'click', function() {
    $( this ).addClass( 'copied' );
    setTimeout( () => {
      $( this ).removeClass( 'copied' );
    }, 2000 );
  } );

} )();