( () => {
  const visionMissionSection = document.querySelector( '.bs-section--vision-mission' );
  if ( !visionMissionSection ) {
    return false; // Exit if the target element is not found
  }
  const visionRow = document.querySelector( '.bs-section--vision-mission .bs-row:first-of-type' );
  const missionRow = document.querySelector( '.bs-section--vision-mission .bs-row:last-of-type' );

  // Event listeners for initial setup
  document.addEventListener( 'DOMContentLoaded', handleResize );
  window.addEventListener( 'resize', debounce( handleResize, 200 ) );

  // Function to handle scroll event
  const handleScroll = () => {
    let scroll = window.scrollY;

    // Adjust transform property based on scroll position
    visionRow.style.transform = `translateY(${scroll / 4}px )`;
    missionRow.style.transform = `translateY(${-scroll / 4}px )`;
  };

  // Function to handle Intersection Observer callback
  function handleIntersection( entries ) {
    entries.forEach( entry => {

      // Add scroll event listener when the target is visible
      if ( entry.isIntersecting ) {
        window.addEventListener( 'scroll', handleScroll ); 
      }
    } );
  }

  // Function to handle resize events
  function handleResize() {
    if ( window.innerWidth > 989 ) {
      const observer = new IntersectionObserver( handleIntersection, {threshold: 0.1} );
      observer.observe( visionMissionSection );
    } else {
      // Remove scroll event listener
      window.removeEventListener( 'scroll', handleScroll );

      // Reset transform values when the window is less than or equal to 989 pixels
      missionRow.style.transform = 'translateY(0)';
      visionRow.style.transform = 'translateY(0)';
    }
  }

  /**
   * Debounce function to limit the frequency of function calls.
   * @param {Function} func - The function to be debounced.
   * @param {number} wait - The time to wait in milliseconds.
   * @returns {Function} - The debounced function.
   */
  function debounce( func, wait ) {
    let timeout;
    return function ( ...args ) {
      clearTimeout( timeout );
      timeout = setTimeout( () => func.apply( this, args ), wait );
    };
  }
} )();