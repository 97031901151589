// /* eslint-env jquery */
( ( $ ) => {
  const duration = 600;
  const footer = $( 'footer' );
  footer
    .find( '.footer__wrapper .navbar-nav > .menu-item-has-children' )
    .append( '<span class="footer__dropdown-helper"></span>' );
  const footerDropDownHelper = footer.find(
    '.footer__wrapper .navbar-nav > li.menu-item-has-children span.footer__dropdown-helper'
  );
  const footerDropDown = footer.find(
    '.footer__wrapper .navbar-nav > .menu-item-has-children > a'
  );
  footerDropDownHelper.add( footerDropDown ).on( 'click', ( e ) => {
    e.preventDefault();
    const $this = $( e.target );
    const subMenuWrapper = $this.closest( 'li.menu-item-has-children' );
    subMenuWrapper.find( '.sub-menu-wrapper' ).toggleClass( 'sub-menu-wrapper--active' );
    const isExpanded = $this.parent().hasClass( 'footer-expanded' );
    const subMenu = $this.prev( 'ul.sub-menu' );
    footer
      .find( '.footer-expanded' )
      .removeClass( 'footer-expanded' )
      .find( '> ul.sub-menu' )
      .slideUp( duration );
    if ( isExpanded ) {
      subMenu.slideUp( duration );
    } else {
      $this.parent().addClass( 'footer-expanded' );
      subMenu.slideDown( duration );
      subMenu.css( {display:'flex'} ); 
    }
  } );

  footer
    .find( '.footer__wrapper .navbar-nav > .menu-item-has-children > .sub-menu' )
    .wrap( '<div class="sub-menu-wrapper"></div>' );
} )( jQuery );
