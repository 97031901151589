( () => {
  const breakPoint = 768;
  const sectionClass = 'bs-section--common-post-popup';

  /**
   * Create a new popup element by cloning .bs-post
   * @param {Element} DOM - The .bs-post element to clone.
   * @returns {Element} - The newly created popupDOM.
   */
  const setupPostPopupDOM = ( DOM ) => {
    const clonedContent = DOM.querySelector( '.bs-post__details' ).cloneNode( true );
    const popupDOM = document.createElement( 'div' );
    const anchor = DOM.querySelector( 'a.bs-post__trigger' );
    const readMoreText = DOM.querySelector( '.bs-post__read-more > p' ).textContent;
    const popupHTML = `
      <div class="post-popup__box">
        <span class="post-popup__close-trigger"></span>
        ${clonedContent.outerHTML}
        <span class="bs-pro-button bs-pro-button--transparent-with-arrow-blue">
          <a href="${anchor.href}" class="bs-pro-button__container" target="${anchor.target}">
            ${readMoreText}
          </a>
        </span>
      </div>`;

    popupDOM.classList.add( 'post-popup' );
    popupDOM.innerHTML = popupHTML;
    popupDOM.querySelector( '.bs-post__read-more' )?.remove();

    return popupDOM;
  };

  /**
   * Close popup
   * Completely remove the popup Element from page
   * Restore page scroll
   * @returns {void}
   */
  const closePostPopup = () => {
    const popups = document.querySelectorAll( `.${sectionClass} .post-popup` );
    if ( popups.length > 0 ) popups.forEach( ( popup ) => popup.remove() );
    document.querySelector( 'body' ).classList.remove( 'prevent-scroll' );
  };

  /**
   * Open a new popup
   * Bind close event with the close trigger
   * Prevent page scrolling
   * @param {Element} DOM - The .bs-post element
   * @returns {void}
   */
  const openPostPopup = ( DOM ) => {
    closePostPopup();
    const popupContent = setupPostPopupDOM( DOM );
    const closeTrigger = popupContent.querySelector( '.post-popup__close-trigger' );
    const section = DOM.closest( 'section.bs-section' );

    section.appendChild( popupContent );
    closeTrigger.addEventListener( 'click', closePostPopup );
    document.querySelector( 'body' ).classList.add( 'prevent-scroll' );
  };

  /**
   * Initialize popup
   * Bind popup events with the .bs-posts selectors
   * Handles browser resize to make sure functionality works only on mobile
   * @returns {void}
   */
  const initPopupEvents = () => {
    const posts = document.querySelectorAll( `.${sectionClass} .bs-post` );
    let eventsBound = false;

    if ( posts.length === 0 ) return;

    const clickHandler = ( e ) => {
      e.preventDefault();
      openPostPopup( e.currentTarget );
    };

    const bindPopupEvents = () => {
      posts.forEach( post => post.addEventListener( 'click', clickHandler ) );
      eventsBound = true;
    };

    const clearPopupEvents = () => {
      posts.forEach( post => post.removeEventListener( 'click', clickHandler ) );
      eventsBound = false;
    };

    const handleOnScreenResize = () => {
      if ( window.innerWidth < breakPoint && !eventsBound ) bindPopupEvents();
      else if ( window.innerWidth >= breakPoint && eventsBound ) clearPopupEvents();
    };
    handleOnScreenResize();
    window.addEventListener( 'resize', handleOnScreenResize );
  };

  // Initialize popup events
  document.addEventListener( 'DOMContentLoaded', initPopupEvents );
  document.addEventListener( 'facetwp-loaded', initPopupEvents );
} )();
