( ( $ ) => {
  $ ( () => {
    const popovers = $( '[data-toggle=popover]' );
    
    if ( !popovers.length ) return;

    require( '@floating-ui/dom' );
    require( 'bootstrap/js/dist/util' );
    require( 'bootstrap/js/dist/popover' );
    popovers.popover();
  } );
} )( jQuery );
