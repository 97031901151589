( ( $ ) => {
  const logKey = e => {
    const sectionRect = e.target.getBoundingClientRect();
    const relativeY = e.clientY - sectionRect.top;
    const mouseXpercentage = Math.round( e.clientX );
    const mouseYpercentage = Math.round( relativeY / 5 );
    $( '.panel-wrapper > div' ).css( {
      transform:`translate(calc(-50vw + ${mouseXpercentage}px), 
      ${mouseYpercentage}px)`
    } );
  };
  $( '.bs-section--common-cta' )?.on( 'mousemove', logKey );
  $( '.bs-section--common-cta' )?.append( `
    <div class="panel-wrapper"><div><div><div><div><div></div></div></div></div></div></div>
  ` );
} )( jQuery );
