( () => {
  const contentBody = document.querySelector( '.bs-section--newsroom-inner-body' );
  if ( !contentBody ) return;

  const uAnchors = contentBody.querySelectorAll( 'u' );
  uAnchors.forEach( ( uTag ) => {
    const anchorLink = uTag.querySelector( 'a' );
    if ( anchorLink ) {
      uTag.parentNode.insertBefore( anchorLink, uTag );
      uTag.parentNode.removeChild( uTag );
    }
  } );
} )();