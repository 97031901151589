window.onload = function( ) {
  // Check if the URL contains "/page/"
  const $section = $( 'section.bs-section--tag-posts' );

  const isMobile = () => {
    return window.matchMedia( '(max-width: 767.98px)' ).matches;
  };

  if ( $section.length > 0 ) {
    const sectionBottom = $( 'section' ).first().offset().top + $( 'section' ).first().outerHeight();

    let scrollOffset = 0;
    
    // Check if it's a mobile device
    if ( isMobile ) {
      // Adjust the scroll offset for mobile devices
      scrollOffset = 50;
    }
    // Scroll to the bottom of the header
    $( 'html, body' ).animate(
      { scrollTop: sectionBottom - scrollOffset },
      500 // Adjust animation speed if needed
    );
  }
};
