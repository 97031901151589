
// Import the MediaVideo class
import MediaVideo from './MediaVideo.module.js';

const mediaVideoInstance = new MediaVideo( 'iframe' );
mediaVideoInstance.observeVideos();

function wistiaMutationObserver( mutationList, observer ) {
  for ( const mutation of mutationList ) {
    const videoEl = mutation.target.querySelector( 'video' );
    if ( mutation.type === 'childList' && videoEl ) {
      new MediaVideo( videoEl ).observeVideos();
      observer.disconnect();
    }
  }
}

Array.from( document.querySelectorAll( '.wistia_responsive_padding' ) )
  .forEach( wistiaEmbed => {
    const mo = new MutationObserver( wistiaMutationObserver );
    mo.observe( wistiaEmbed, { subtree: true, childList: true, attributes: false } );
  } );
