const calcScrollTop = () => {
  let windowResponsive = 80;
  const windowWidth = $( window ).width();
  if ( windowWidth >= 1200 ) {
    windowResponsive = 137;
  }
  return windowResponsive;
};

$( () => {
  let isClicked = false;
  const $window = $( window );
  const $tocHeadings = $( '.bs-div--blog-inner-toc ul li a' );
  const $insightInnerAside = $( '.bs-div--blog-inner-toc' );
  
  if ( $tocHeadings.length === 0 ) return;

  const checkViewportHeight = () => {
    const viewportHeight = $window.height();
    const stickyTop = $( 'header' ).height() + 60;
    $insightInnerAside.toggleClass( 'sidebar-sticky', ( viewportHeight - stickyTop ) > $insightInnerAside.height() );
  };

  const tocScroll = () => {
    $tocHeadings.on( 'click', function( e ) {
      e.preventDefault();
      const $selectedHeading = $( $( this ).attr( 'href' ) );

      isClicked = true;
      $tocHeadings.removeClass( 'active' );
      $( this ).addClass( 'active' );

      if ( $selectedHeading.length ) {
        const headingCoords = $selectedHeading[0].getBoundingClientRect();
        let scrollTopOffset = calcScrollTop();

        $( 'html, body' ).animate( {
          scrollTop: headingCoords.top + window.scrollY - scrollTopOffset + 20
        }, 800, () => {
          isClicked = false;
        } );
      }
    } );
  };

  const handleScroll = () => {
    if ( !isClicked ) {
      const scrollDistance = $window.scrollTop();
      const $scrollItem = $( '.bs-section--blog-inner-content .bs-row:last-child h2' );

      $scrollItem.each( ( i, element ) => {
        if ( $( element ).offset().top <= scrollDistance + 137 ) {
          $tocHeadings.removeClass( 'active' ).eq( i ).addClass( 'active' );
        }
      } );
    }
  };

  $window.on( 'resize load', () => {
    checkViewportHeight();
    $tocHeadings.first().addClass( 'active' );
  } );
  $window.on( 'scroll load', handleScroll ).scroll();
  
  tocScroll();
} );