( $ => {

  const accordionBtn = $( '.bs-section--academy-faq-section .accordion__block__btn' );
  
  const handleAccordionClick = e => {
    let navHeight = 90;
    const breakpoint = 1200;
    if( window.innerWidth > 992 ) {
      navHeight = 110;
    }
    if ( window.innerWidth < breakpoint ) { 
      const $this = $( e.target );  
      setTimeout( function() {
        let topPosition = $this.offset().top - navHeight;
        $( 'html,body' ).animate(
          {
            scrollTop: topPosition,
          },
          'slow'
        );
      }, 300 );
    }

  };
  
  accordionBtn.on( 'click', handleAccordionClick );


} )( jQuery );
  