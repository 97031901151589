class MediaVideo {
  constructor( selectors ) {
    if ( typeof selectors === 'string' ) {
      this.videoElements = document.querySelectorAll( selectors );
    } else if ( selectors?.nodeType === Node.ELEMENT_NODE ) {
      this.videoElements = [selectors];
    }
    this.ytApi = false;
    this.vmApi = false;
    this.wsApi = false;
    this.repeatTime = 10000;
  }

  // Get the type of video by URL
  getVideoTypeByUrl( vdUrl, videoElement = null ) {
    if ( typeof vdUrl !== 'string' ) {
      return 'local';
    }

    if (
      videoElement &&
      typeof videoElement.id === 'string' &&
      videoElement.id.indexOf( 'wistia' ) !== -1 &&
      videoElement.parentNode?.classList.contains( 'w-video-wrapper' )
    ) {
      return 'wistia';
    }

    switch ( true ) {
    case vdUrl.indexOf( 'vimeo' ) !== -1:
      return 'vimeo';
    case vdUrl.indexOf( 'youtube' ) !== -1:
      return 'youtube';
    case vdUrl.indexOf( 'wistia' ) !== -1:
      return 'wistia';
    default:
      return 'local';
    }
  }

  // Add API scripts dynamically
  addAPiScripts( url ) {
    const script = document.createElement( 'script' );
    script.src = url;
    script.async = true;
    document.head.appendChild( script );
  }

  // Get iframe URL parameters based on video type
  getIframeUrlParameters( vdUrl, videoElement = null ) {
    const videoType = this.getVideoTypeByUrl( vdUrl, videoElement );
    switch ( videoType ) {
    case 'youtube':
      return '?enablejsapi=1&mute=1&autoplay=1&modestbranding=1&rel=0&showinfo=0&controls=0';
    case 'vimeo':
      return '?controls=0?autoplay=1&muted=1&loop=1&title=1&byline=0';
    case 'wistia':
      return '?autoPlay=true&muted=true&playbar=false&playButton=false&smallPlayButton=false&relatedVideos=false';
    default:
      return '';
    }
  }

  // Load API script based on video type
  loadAPiScript( vTyp ) {
    switch ( vTyp ) {
    case 'youtube':
      this.addAPiScripts( 'https://www.youtube.com/iframe_api' );
      break;
    case 'vimeo':
      this.addAPiScripts( 'https://player.vimeo.com/api/player.js' );
      break;
    case 'wistia':
      this.addAPiScripts( '//fast.wistia.com/assets/external/E-v1.js' );
      break;
    default:
      break;
    }
  }

  // Set attributes for video iframe elements
  setIframeAttributes( videoElement, videoUrl, i ) {
    const videoType = this.getVideoTypeByUrl( videoUrl, videoElement );
    if ( videoType == 'wistia' || videoType == 'youtube' || videoType == 'vimeo' ) {
      videoElement.setAttribute( 'src', '' );
      videoElement.setAttribute( 'data-src', videoUrl + this.getIframeUrlParameters( videoUrl, videoElement ) );
      videoElement.setAttribute( 'id', `embed-${i}` );
      // eslint-disable-next-line max-len
      videoElement.setAttribute(
        'allow',
        'accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      );
    }
  }

  // Add API script based on video type
  addAPiScript( videoType ) {
    switch ( videoType ) {
    case 'youtube':
      if ( !this.ytApi ) {
        this.loadAPiScript( videoType );
        this.ytApi = true;
      }
      break;
    case 'vimeo':
      if ( !this.vmApi ) {
        this.loadAPiScript( videoType );
        this.vmApi = true;
      }
      break;
    case 'wistia':
      if ( !this.wsApi ) {
        this.loadAPiScript( videoType );
        this.wsApi = true;
      }
      break;
    default:
      break;
    }
  }

  // Remove poster image for video element
  removeImagePoster( videoElement ) {
    const parentWrapper = videoElement.closest( '.bs-div--inline-video' );
    if ( parentWrapper ) {
      parentWrapper.querySelector( '.video-wrapper__poster-image' ).style.display = 'none';
      parentWrapper.classList.add( 'playing' );
    }
  }

  getWistiaVideoFromEmbeddedVideoEl( videoElement ) {
    let wVideo;
    const wrapper = videoElement
      .closest( '.wistia_responsive_padding' )
      .querySelector( '[class*="wistia_async_"]' );
    if ( wrapper ) {
      wVideo = Wistia.api( wrapper.id ); // eslint-disable-line no-undef
    }
    return wVideo;
  }

  // Pause video playback
  pauseVideo( videoElement, type ) {
    const parentWrapper = videoElement.closest( '.bs-div--inline-video' );

    switch ( type ) {
    case 'youtube':
      // eslint-disable-next-line max-len
      videoElement.contentWindow.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        'https://www.youtube.com'
      );
      break;
    case 'vimeo':
      videoElement.contentWindow.postMessage( '{"method":"pause"}', 'https://player.vimeo.com' );
      break;
    case 'wistia':
      if ( !videoElement.contentWindow ) {
        this.getWistiaVideoFromEmbeddedVideoEl( videoElement )?.pause();
      } else {
        videoElement.contentWindow.postMessage(
          JSON.stringify( { method: 'pause', value: true } ),
          'https://fast.wistia.com'
        );
      }
      break;
    default:
      videoElement.pause();
      break;
    }

    parentWrapper?.classList.remove( 'playing' );
    parentWrapper?.classList.add( 'pause' );
  }

  // Play video playback
  playVideo( videoElement, type ) {
    const parentWrapper = videoElement.closest( '.bs-div--inline-video' );
    switch ( type ) {
    case 'youtube':
      // eslint-disable-next-line max-len
      videoElement.contentWindow.postMessage(
        '{"event":"command","func":"playVideo","args":""}',
        'https://www.youtube.com'
      );
      break;
    case 'vimeo':
      videoElement.contentWindow.postMessage( '{"method":"play"}', 'https://player.vimeo.com' );
      break;
    case 'wistia':
      if ( !videoElement.contentWindow ) {
        this.getWistiaVideoFromEmbeddedVideoEl( videoElement )?.play();
      } else {
        videoElement.contentWindow.postMessage(
          JSON.stringify( { method: 'play', value: true } ),
          'https://fast.wistia.com'
        );
      }
      break;
    default:
      videoElement.play();
      break;
    }
    parentWrapper?.classList.remove( 'init-play', 'pause' );
    parentWrapper?.classList.add( 'playing' );
    videoElement.classList.remove( 'looping' );
  }

  // Initial play video
  initialPlayVideo( videoElement, videoType ) {
    switch ( videoType ) {
    case 'youtube':
      // eslint-disable-next-line max-len
      videoElement.contentWindow.postMessage(
        '{"event":"command","func":"playVideo","args":""}',
        'https://www.youtube.com'
      );
      break;
    case 'vimeo':
      videoElement.contentWindow.postMessage( '{"method":"play"}', 'https://player.vimeo.com' );
      break;
    default:
      videoElement.classList.remove( 'looping' );
      break;
    }

    setTimeout( () => {
      if ( videoElement.classList.contains( 'looping' ) ) {
        this.seekToZero( videoElement, videoType );
        this.initialPlayVideo( videoElement, videoType );
      }
    }, this.repeatTime );
  }

  // Seek video playback to zero
  seekToZero( videoElement, videoType ) {
    switch ( videoType ) {
    case 'youtube':
      // eslint-disable-next-line max-len
      videoElement.contentWindow.postMessage(
        '{"event":"command","func":"seekTo","args": [0]}',
        'https://www.youtube.com'
      );
      break;
    case 'vimeo':
      videoElement.contentWindow.postMessage( '{"method":"seekTo","value":0}', 'https://player.vimeo.com' );
      break;
    case 'wistia':
      if ( !videoElement.contentWindow ) {
        this.getWistiaVideoFromEmbeddedVideoEl( videoElement )?.time( 0 );
      } else {
        videoElement.contentWindow.postMessage(
          JSON.stringify( { method: 'time', args: [0] } ),
          'https://fast.wistia.com'
        );
      }
      break;
    default:
      videoElement.currentTime = 0;
      break;
    }
  }

  // Observer for video players
  videoPlayersObserver() {
    const handleYouTubeVideo = ( videoElement, entryUrl, parentWrapper ) => {
      if ( videoElement.getAttribute( 'src' ) != '' ) {
        this.seekToZero( videoElement, 'youtube' );
        parentWrapper?.querySelector( '.play-button' ).classList.remove( 'hide' );
      }
    };

    const handleVimeoVideo = ( videoElement, entryUrl, parentWrapper ) => {
      if ( videoElement.getAttribute( 'src' ) != '' ) {
        this.seekToZero( videoElement, 'vimeo' );
        parentWrapper?.querySelector( '.play-button' ).classList.remove( 'hide' );
      }
    };

    const handleWistiaVideo = ( videoElement, entryUrl, parentWrapper ) => {
      if ( videoElement.getAttribute( 'src' ) != '' ) {
        // Commented because banner get removed on load 2024-04-08
        // this.seekToZero( videoElement, 'wistia' );
        parentWrapper?.querySelector( '.play-button' ).classList.remove( 'hide' );
      }
    };

    const handleLocalVideo = ( videoElement, entryUrl, parentWrapper ) => {
      if ( videoElement.getAttribute( 'src' ) != '' ) {
        this.seekToZero( videoElement, 'local' );
        parentWrapper?.querySelector( '.play-button' )?.classList.remove( 'hide' );
        if ( videoElement.hasAttribute( 'autoplay' ) ) {
          videoElement.play();
        }
      }
    };

    const videoPlayersObserver = new IntersectionObserver( entries => {
      entries.forEach( entry => {
        if ( entry.intersectionRatio > 0 ) {
          const videoElement = entry.target;
          const entryUrl = videoElement.getAttribute( 'data-src' ) || videoElement.getAttribute( 'src' );
          const parentWrapper = videoElement.closest( '.bs-div--inline-video' );
          const videoType = this.getVideoTypeByUrl( entryUrl, videoElement );

          switch ( videoType ) {
          case 'youtube':
            handleYouTubeVideo( videoElement, entryUrl, parentWrapper );
            break;
          case 'vimeo':
            handleVimeoVideo( videoElement, entryUrl, parentWrapper );
            break;
          case 'wistia':
            handleWistiaVideo( videoElement, entryUrl, parentWrapper );
            break;
          default:
            handleLocalVideo( videoElement, entryUrl, parentWrapper );
            break;
          }
        } else {
          const videoElement = entry.target;
          const videoUrl = videoElement.getAttribute( 'src' );
          if ( videoUrl != '' && !videoElement.classList.contains( 'looping' ) ) {
            const videoType = this.getVideoTypeByUrl( videoUrl, videoElement );
            this.pauseVideo( videoElement, videoType );
          }
        }
      } );
    } );

    this.videoElements.forEach( player => {
      videoPlayersObserver.observe( player );
    } );
  }

  observeVideos() {
    this.videoPlayersObserver();
  }
}

export default MediaVideo;