/**
 * When the "show" class get added or removed,
 * Add "active" class to expanded cards
 * @param {HTMLElement} accordion
 * @return {Function}
 */
function unqkAdvanceAccordionMutationObserverCallback( accordion ) {
  return ( mutationList ) => {
    for ( const mutation of mutationList ) {
      if (
        mutation.type === 'attributes' &&
          mutation.attributeName === 'class' &&
          ( mutation.target.classList.contains( 'collapse' ) || mutation.target.classList.contains( 'collapsing' ) ) &&
          ( mutation.oldValue.indexOf( 'show' ) >= 0 || mutation.target.classList.contains( 'show' ) )
      ) {
        accordion.querySelectorAll( '.accordion__block__btn[aria-expanded=true]' ).forEach( button => {
          const card = button.parentNode;
          if ( !card.classList.contains( 'active' ) ) {
            card.classList.add( 'active' );
          }
        } );
      }
    }
  };
}
/**
   * Setup MutationObserver on accordion to observe class updates
   * @param {HTMLElement} accordion
   */
function unqkSetupAdvanceAccordionMutationObserver( accordion ) {
  const observer = new MutationObserver( unqkAdvanceAccordionMutationObserverCallback( accordion ) );
  observer.observe( accordion, {
    attributes: true,
    attributeFilter: ['class'],
    attributeOldValue: true,
    childList: true,
    subtree: true,
  } );
}
/**
   * Add "active" class to expanded to cards on button click
   * @param {NodeListOf<HTMLElement>} buttons
   * @param {number} currentButtonIndex
   */
function unqkAdvanceAccordionResetActiveClass( buttons, currentButtonIndex ) {
  buttons.forEach( ( btn, btnIndex ) => {
    if ( currentButtonIndex === btnIndex ) {
      return;
    }
    const card = btn.parentNode;
    if ( card.querySelector( '.collapse.show' ) && !card.classList.contains( 'active' ) ) {
      card.classList.add( 'active' );
    }
  } );
}
( jQ => {
  jQ( () => {
    const accordions = document
    // eslint-disable-next-line max-len
      .querySelectorAll( '.bs-advance-accordion.bs-advance-accordion--toggle-accordian > .bs-advance-accordion__right-container' );
    accordions.forEach( accordion => {
      const buttons = accordion.querySelectorAll( '.accordion__block__btn' );
      buttons.forEach( ( button, buttonIndex ) => {
        jQ( button ).on( 'click', () => {
          unqkAdvanceAccordionResetActiveClass( buttons, buttonIndex );
        } );
        unqkSetupAdvanceAccordionMutationObserver( accordion );
        delete document.querySelector( button.dataset.target ).dataset.parent;
      } );
    } );
  } );
} )( jQuery.noConflict( true ) );
  