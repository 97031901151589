import { debounce } from 'lodash';


$( () => {
  const $window = $( window );
  function setPopoverPlacement() {
    $( '.popoverSpan' ).each( function() {
      let placement = $( window ).width() < 576 ? 'bottom' : 'right';
      let trigger = $( window ).width() < 576 ? 'click' : 'hover';
      $( this ).popover( 'dispose' ).popover( {
        placement: placement,
        trigger: trigger
      } );
    } );
  }
  function closeOtherPopovers( current ) {
    $( '.popoverSpan' ).not( current ).popover( 'hide' );
  }

  $( 'body' ).on( 'click', '.popoverSpan', function( ) {
    closeOtherPopovers( this ); // Close other popovers when one is opened
  } );

  setPopoverPlacement();
  $window.on( 'resize', debounce( setPopoverPlacement, 500 ) );
} );