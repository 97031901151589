import { debounce } from 'lodash';

( () => {
  
  const wrapper = $( '.bs-div--pgb-code-wrapper-div .bs-div__inner' );
  const content = $( '.bs-div--pgb-code-wrapper-div p' );
  const divContainer = $( '.bs-div--security-copy-text-div .bs-div__inner' );
  const closeButton = $( '.bs-pro-button--slide-top' );

  $( '.bs-div--pgb-code-wrapper-div p' ).click( async function () {
    wrapper.css( 'height', this.scrollHeight + 30 + 'px' );
    divContainer.css( 'overflow', 'hidden' );
    closeButton.css( 'transition', 'opacity 0.5s ease' ).css( 'opacity', '1' );
    content.addClass( 'active' );
    setTimeout( () => {
      wrapper.addClass( 'active' );
    }, 500 );
  } );

  $( '.bs-pro-button--slide-top' ).click( async function () {
    const adminBarHeight = $( '#wpadminbar' ).length > 0 ? $( '#wpadminbar' ).height() : 0;
    const headerHeight = $( 'header' ).height() + adminBarHeight + 125;
    const currentScrollPosition = window.scrollY;
    const parentTopPosition = $( '.bs-div--pgb-div' ).get( 0 ).getBoundingClientRect().top;
    const elementTopPosition = parentTopPosition + currentScrollPosition - headerHeight;
    divContainer.css( 'overflow', 'hidden' );
    closeButton.css( 'transition', 'opacity 0.5s ease' ).css( 'opacity', '0' );
    wrapper.css( 'height', '50px' );
    window.scrollTo( { top: elementTopPosition, behavior: 'smooth' } );
    setTimeout( () => {
      content.removeClass( 'active' );
      wrapper.removeClass( 'active' );
    }, 500 );
  } );

  const debounceHandler = () => {
    wrapper.css( 'height', content.height() + 34 + 'px' );
  };
  $( window ).on( 'resize', debounce( debounceHandler, 500 ) );

} )();