$( document ).ready( function () {
  // Function to update the progress bar
  function updateProgressBar() {
    const scrollPos = $( document ).scrollTop();
    const windowHeight = $( window ).height();
    const documentHeight = $( '.bs-section--newsroom-inner-banner' ).innerHeight()
      + $( '.bs-section--inner-js-content' ).innerHeight();
    const progress = ( scrollPos / ( documentHeight - windowHeight ) ) * 100;

    // Update the progress bar width
    $( '.bs-section--progress-bar-scroll .container-fluid' ).css( 'width', progress + '%' );
  }

  // Initial update when the page is loaded
  updateProgressBar();

  // Listen for scroll events
  $( window ).on( 'scroll', function () {
    // Update the progress bar on scroll
    updateProgressBar();
  } );
} );
