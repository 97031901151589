let timeOut;
const headerScrollUp = ( currentScrollPos, preScrollPos, header ) => {
  if ( currentScrollPos < preScrollPos && currentScrollPos != 0 ) {
    clearTimeout( timeOut );
    timeOut = setTimeout( () => {
      header.addClass( 'scrolled-up' );
      $( 'body' ).addClass( 'scrolled-up' );
    }, 100 );
  } else {
    header.removeClass( 'scrolled-up' );
    $( 'body' ).removeClass( 'scrolled-up' );
  }
};

const isDesktop = () => {
  if ( $( window ).width() >= 992 ) {
    return true;
  }
};

const getScrollPos = () => {
  let scrollPos;
  if ( $( 'body' ).hasClass( 'admin-bar' ) ) {
    scrollPos = 32;
  } else {
    scrollPos = 0;
  }

  return scrollPos;
};

const headerTransition = ( currentScrollPos, header, notifyBar, transformY ) => {
  if ( currentScrollPos > getScrollPos() ) {
    header.addClass( 'scrolled' );
    notifyBar.css( 'transform', 'translateY(0px)' );
  } else {
    if ( isDesktop() ) {
      transformY = 100 >= currentScrollPos > 0 ? -currentScrollPos / 5 : -21;
      notifyBar.css( 'transform', 'translateY(' + transformY + 'px)' );
    }
    header.removeClass( 'scrolled' );
  }
};

export { headerScrollUp, isDesktop, getScrollPos, headerTransition };
