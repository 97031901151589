/* eslint-disable no-undef, no-unused-vars */
$( () => {

  /**
   * stop facet one letter search
   */
  /* eslint-disable no-undef */
  $( document ).on( 'keyup', '.facetwp-type-search .facetwp-search', ( e ) => {
    const inputValue = e.currentTarget.value;
    if ( inputValue.length > 0 && inputValue.length < 2 ) {
      FWP.auto_refresh = false;
      setTimeout( () => {
        FWP.is_refresh = false;
      }, 150 );
    } else {
      FWP.auto_refresh = true;
    }
  } );
  /* eslint-enable */

  function cleanFacetResult() {
    const searchSelections = $( '.facetwp-selections li' );
    searchSelections.each( function( i, el ) {
      const itemType = $( el ).attr( 'data-facet' );
      const itemValue = $( el ).find( '.facetwp-selection-value' ).text();

      if ( itemType == 'search' && itemValue.trim() == '' ) {
        $( '.facetwp-type-reset' ).hide();
        $( el ).hide();
      } else {
        $( '.facetwp-type-reset' ).show();
      }
    } );
  }

  const hideFiltersWhenEmpty = () => {
    const allFacetOptions = $( '.facetwp-type-fselect .fs-options' );
    if ( allFacetOptions.length > 0 ) {
      allFacetOptions.each( ( i, e ) => {
        const element = $( e );
        if ( element.find( 'div' ).length === 0 ) {
          element.closest( '.bs-column' ).hide();
        } else {
          element.closest( '.bs-column' ).show();
        }
      } );
    }
  };

  const addWrapperWithImageClass = () => {
    const wrapperDiv = $( '.bs-posts .bs-posts__list' );
    if ( wrapperDiv.find( 'div.has-image' ).length > 0 ) {
      wrapperDiv.addClass( 'with-image' );
    } else {
      wrapperDiv.removeClass( 'with-image' );
    }
  };
  
  /* eslint-disable no-undef */
  $( document ).on( 'facetwp-refresh', function () {
    if ( FWP.soft_refresh === true ) {
      FWP.enable_scroll = true;
    } else {
      FWP.enable_scroll = false;
    }
  } );
  /* eslint-enable */

  $( document ).on( 'click', '.facetwp-page', ( e ) => {
    const adminBarHeight = $( '#wpadminbar' ).length > 0 ? $( '#wpadminbar' ).height() : 0;
    const headerHeight = $( 'header' ).height() + adminBarHeight - 60;
    const spacing = screen.width > 768 ? headerHeight : headerHeight + 60;
    $( 'html, body' ).animate(
      { scrollTop: $( e.currentTarget ).closest( 'section' ).offset().top - spacing },
      1
    );
  } );

  /* eslint-disable no-undef */
  $( document ).on( 'facetwp-refresh', () => {
    if ( null !== FWP.active_facet ) {
      const facet = FWP.active_facet;
      const facet_name = facet.attr( 'data-name' );
      $( '.facetwp-facet[data-name="'+ facet_name +'"]' ).addClass( 'current-loading-facetwp' );
    }
  } );
  /* eslint-enable */

  $( document ).on( 'facetwp-refresh', () => {
    $( '.facetwp-template' ).addClass( 'facetwp-template--loading' );
  } );

  $( document ).on( 'facetwp-loaded', () => {
    setTimeout( () => {
      cleanFacetResult();
    }, 0 );
    addWrapperWithImageClass();
    hideFiltersWhenEmpty();
    $( '.facetwp-template' ).removeClass( 'facetwp-template--loading' );
    $( '.facetwp-facet' ).removeClass( 'current-loading-facetwp' );
  } );

  cleanFacetResult();

  $( window ).on( 'load resize', function () {
    addWrapperWithImageClass();
    hideFiltersWhenEmpty();
  } );

  hideFiltersWhenEmpty();   
  
} );

/**
 * partner page cards click load required facets
 */
/* eslint-disable no-undef */
function customFacetLoading ( event, partner_types ) {  
  event.preventDefault();
  FWP.facets['partner_type'] = partner_types;   
  FWP.fetchData();
  FWP.setHash();    
}

/**
 * partner page cards click scroll to filter section
 */
function scrollToFilter( ){
  $( 'html, body' ).animate(
    { scrollTop: $( '#our-partners' ).offset().top },
    'slow'
  );
}

/**
 * partner page cards click identified clicked div
 */
function setupFacetEventListeners() {
  const technology_partner = document.getElementById( 'technology-partner' );
  if( technology_partner ) {
    technology_partner
      .addEventListener( 'click', ( event ) => {
        customFacetLoading( event, ['technology'] );
        scrollToFilter();
      } );
  }

  const cloud_partner = document.getElementById( 'cloud-partner' );
  if( cloud_partner ) {
    cloud_partner
      .addEventListener( 'click', ( event ) => {
        customFacetLoading( event, ['cloud-services'] );
        scrollToFilter();
      } );
  }

  const consulting_partner = document.getElementById( 'consulting-partner' );
  if( consulting_partner ) {
    consulting_partner
      .addEventListener( 'click', ( event ) => {
        customFacetLoading( event, ['commercial-consulting-implementation-partners'
          , 'government-consulting-implementation-partners'] );
        scrollToFilter();
      } );
  }
}

function unqkFacetWpSelectionsMutationObserver( mutationList ) {
  for ( const mutation of mutationList ) {
    const facetSelectionLabels = mutation.target.querySelectorAll( '.facetwp-selection-label' );
    Array.from( facetSelectionLabels )
      .forEach( label => {
        label.innerText = label.innerText.replace( ':', '' );
      } );
  }
}

document.addEventListener( 'facetwp-loaded', () => {
  const facetSelectionsWrapper = document.querySelector( '.facetwp-selections' );
  if ( !facetSelectionsWrapper ) {
    return;
  }
  new MutationObserver( unqkFacetWpSelectionsMutationObserver )
    .observe( facetSelectionsWrapper, { subtree: true, childList: true } );
} );

/**
 * partner page cards click events handler
 */
document.addEventListener( 'facetwp-loaded', setupFacetEventListeners );
