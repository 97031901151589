/**
 * Initiate SVGator player
 * @param {DOM} objectTag Object tag which is contains the SVG animation
 */
const initiatePlayer = ( objectTag ) => {
  const SVGDocument = objectTag.contentDocument || objectTag.contentWindow?.document;
  const SVGAnim = SVGDocument.querySelector( 'svg' );

  return SVGAnim?.svgatorPlayer || null;
};

/**
 * Play SVG animation
 * @param {DOM} objectTag Object tag which is contains the SVG animation
 * @param {boolean} isMobile
 */
const playCurrentAnimation = ( objectTag, isMobile=false ) => {
  const player = initiatePlayer( objectTag );

  if ( player && typeof player.play === 'function' ) {
    player.set( 'iterations', 0 );
    if ( isMobile ) {
      player.play();
    } else {
      player.stop();
      player.play();
    }
  }
};

/**
 * Stop SVG animation
 * @param {DOM} objectTag Object tag which is contains the SVG animation
 */
const stopAnimation = ( objectTag ) => {
  const player = initiatePlayer( objectTag );
  if ( player && typeof player.stop === 'function' ) {
    player.stop();
  }
};

/**
 * Hide all SVG animations
 * @param {DOM} animations
 */
const hideAllAnimations = ( animations ) => {
  animations.forEach( ( animation ) => {
    animation.classList.remove( 'visible' );
    animation.classList.add( 'hide' );
  } );
};

/**
 * Apply spacing between cards dynamically
 * @param {DOM} card 
 * @param {integer} index 
 * @param {integer} lastIndex 
 */
const applyCardSpacing = ( card, index, lastIndex ) => {
  if ( index !== ( lastIndex - 1 ) ) {
    const space = ( window.innerHeight / 3 ) - 206;
    card.style.marginBottom = `${ space < 60 ? 60 : space }px`;
  }
};

/**
 * Animate on mobile
 * @param {DOM} cards
 */
const animateOnMobile = ( cards ) => {
  const handleIntersection = ( entries, observer ) => {
    entries.forEach( ( entry ) => {
      const svgObject = entry.target;
      if ( entry.isIntersecting ) {
        playCurrentAnimation( svgObject, true );
        observer.unobserve( svgObject );
      } else {
        stopAnimation( svgObject );
      }
    } );
  };

  const observer = new IntersectionObserver( handleIntersection, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  } );

  cards.forEach( ( card ) => {
    if ( card.querySelectorAll( '.wp-block-e25m-svg-animation-block object' ).length > 0 ) {
      const svgObject = card.querySelector( '.wp-block-e25m-svg-animation-block object' );
      observer.observe( svgObject );
    }
  } );
};

document.addEventListener( 'DOMContentLoaded', function () {
  const section = document.querySelector( '.bs-section--home-digital-transformation' );

  if ( section ) {
    let currentActiveCardIndex = 0;
    const cardItems = section.querySelectorAll( '.bs-advance-accordion__right-container .card' );
    const svgAnimation = section.querySelectorAll( '.bs-advance-accordion__left-content-panel' );

    const findNewActiveCardIndex = ( cards ) => {
      const getEleCenter = window.innerHeight / 2;
      let newActiveCardIndex = -1;
      cards.forEach( ( item, index ) => {
        applyCardSpacing( item, index, cards.length );
        const itemXY = item.getBoundingClientRect();
        if ( getEleCenter >= itemXY.top && itemXY.bottom > ( getEleCenter * ( 1 - 0.8 ) ) ) {
          newActiveCardIndex = index;
        }
      } );

      return newActiveCardIndex;
    };

    const updateCardAndAnimation = ( newActiveCardIndex ) => {
      hideAllAnimations( svgAnimation );
      svgAnimation[newActiveCardIndex].classList.remove( 'hide' );
      svgAnimation[newActiveCardIndex].classList.add( 'visible' );
      cardItems.forEach( ( card ) => card.classList.remove( 'active' ) );
      cardItems[newActiveCardIndex].classList.add( 'active' );

      const svgObject = svgAnimation[newActiveCardIndex].querySelector( '.wp-block-e25m-svg-animation-block object' );
      playCurrentAnimation( svgObject );
      currentActiveCardIndex = newActiveCardIndex;
    };

    const handleMediaChange = ( media, cards ) => {
      if ( media.matches ) {
        const newActiveCardIndex = findNewActiveCardIndex( cards );
        if ( newActiveCardIndex !== -1 && newActiveCardIndex !== currentActiveCardIndex ) {
          updateCardAndAnimation( newActiveCardIndex );
        }
      } else {
        animateOnMobile( cardItems );
      }
    };

    const mediaObject = window.matchMedia( '(min-width: 576px)' );
    handleMediaChange( mediaObject, cardItems );

    const handleScroll = () => {
      handleMediaChange( mediaObject, cardItems );
    };

    window.addEventListener( 'scroll', handleScroll );
    mediaObject.addEventListener( 'change', handleScroll );
  }
} );
