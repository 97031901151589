$( document ).ready( function () {
  const sections = document.querySelectorAll( '.bs-div--security-section-div' );
  const navLinks = $( '.bs-row--security-stick-row a' );

  const initialHighlightedSection = sections[0];
  if( initialHighlightedSection ) {
    const initialHighlightedNavLink = $( `.bs-row--security-stick-row a[href="#${initialHighlightedSection.id}"]` );
    initialHighlightedNavLink.addClass( 'active' );
  }

  const observerOptions = {
    root: null,
    rootMargin: '-27%  -50% -73%',
    threshold: 0,
  };

  const observer = new IntersectionObserver( function ( entries ) {
    entries.forEach( entry => {
      if ( entry.isIntersecting ) {
        const navLink = $( `.bs-row--security-stick-row a[href="#${entry.target.id}"]` );
        navLinks.removeClass( 'active' );
        navLink.addClass( 'active' );
      }
    } );
  }, observerOptions );

  sections.forEach( section => {
    observer.observe( section );
  } );
} );

$( '.bs-row--security-stick-row .bs-pro-button__container' ).click( function ( e ) {
  const adminBarHeight = $( '#wpadminbar' ).length > 0 ? $( '#wpadminbar' ).height() : 0;
  const headerHeight = $( 'header' ).height() + adminBarHeight + 90;
  $( 'html, body' ).animate( {
    scrollTop: $( e.target.hash ).offset().top - headerHeight,
  }, 1000 );
} );
