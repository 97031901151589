
( $ => {


  const whySectionAccordionSelector = '.bs-section--solve-the-challenges .accordion__block__btn';
  const lowCodeAccordionSelector = '.bs-section--low-code-benefit-accordion .accordion__block__btn';
  const codelessAccordion = '.bs-section--platform-overview-modern-way .accordion__block__btn';
  const accordionBtn = $( whySectionAccordionSelector + ','+ lowCodeAccordionSelector+','+codelessAccordion );  
  const handleAccordionClick = e => {
    const breakpoint = 1200;
    if ( window.innerWidth < breakpoint ) { 
      const $this = $( e.target );
  
      setTimeout( function() {
        let topOffset = window.innerWidth > 991 ? 155 : 110;
        let topPosition = $this.offset().top - topOffset;
        $( 'html,body' ).animate(
          {
            scrollTop: topPosition,
          },
          'slow'
        );
      }, 300 );
    }

  };
  
  accordionBtn.on( 'click', handleAccordionClick );


} )( jQuery );
  