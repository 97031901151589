function cardHoverEffect( selector ) {
  const cards = document.querySelectorAll( selector );

  cards.forEach( card => {
    const anchor = card.querySelector( 'a' );
    if ( anchor !== null ) {
      card.addEventListener( 'click', ( e ) => {
        e.preventDefault();
        const target = anchor.getAttribute( 'target' ) || '_self';
        window.open( anchor.href, target );
      } );
    }
  } );

  if ( window.innerWidth > 768 ) {
    cards.forEach( box => {
      if ( box.querySelectorAll( 'a' ).length == 0 ) {
        return;
      }

      box.addEventListener( 'mouseover', () => {
        cards.forEach( otherBox => {
          if ( otherBox !== box ) {
            otherBox.style.opacity = 0.45;
            otherBox.style.boxShadow = 'none';
          }
        } );
      } );

      box.addEventListener( 'mouseout', () => {
        cards.forEach( otherBox => {
          otherBox.style.opacity = 1;
          otherBox.style.boxShadow = '0 10px 60px 0 rgba(0, 0, 0, 0.65)';
        } );
      } );
    } );
  }
}

document.addEventListener( 'DOMContentLoaded', () => {
  cardHoverEffect( '.bs-div--home-value-card' );
  cardHoverEffect( '.bs-div--partner-card' );
} );
