document.addEventListener( 'DOMContentLoaded', function () {
  const parallaxElement = document.querySelectorAll( '.bs-column--home-main-banner-parallax .media-elements' );

  for ( let i = 1; i < parallaxElement.length; i++ ) {
    setTimeout( () => {
      parallaxElement[i].classList.add( 'show' );
    }, 300 * i );

    if ( i === ( parallaxElement.length - 1 ) ) {
      setTimeout( () => {
        parallaxElement[0].classList.add( 'show' );
      }, 500 * ( i + 1 ) );
    }
  }

  window.addEventListener( 'scroll', () => {
    const scrollY = window.scrollY;
    if ( parallaxElement.length ) {
      parallaxElement[0].style.transform = `translateY(-${scrollY * 0.06}px)`;
      parallaxElement[1].style.transform = `translate(-${scrollY * 0.2}px, -${scrollY * 0.4}px)`;
      parallaxElement[2].style.transform = `translate(-${scrollY * 0.2}px, ${scrollY * 0.1}px)`;
      parallaxElement[3].style.transform = `translate(${scrollY * 0.25}px, ${scrollY * 0.2}px)`;
    }
  } );

  // Glow effect
  const logKey = e => {
    const sectionRect = e.target.getBoundingClientRect();
    const relativeY = e.clientY - sectionRect.top;
    const mouseXpercentage = Math.round( e.clientX );
    const mouseYpercentage = Math.round( relativeY / 1.3 );
    $( '.panel-wrapper > div' ).css( {
      transform:`translate(calc(-50vw + ${mouseXpercentage}px), 
      ${mouseYpercentage}px)`
    } );
  };
  $( '.bs-section--home-main-banner, .bs-section--home-banner' )?.on( 'mousemove', logKey );
  $( '.bs-section--home-main-banner' )?.append( `
    <div class="panel-wrapper"><div><div><div><div><div></div></div></div></div></div></div>
  ` );
} );
