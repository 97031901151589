import { debounce } from 'lodash';

$( () => {
  const $window = $( window );
  const classNames = [
    { className: '.bs-div--academy-week-details-header', elementsPerRow: 3 },
  ];

  const setMaxHeight = ( className, elementsPerRow ) => {
    const $elements = $( className );

    if ( $window.width() <= 1200 ) {
      $elements.css( 'height', 'auto' );
      return; // Exit the function early if width is below 1200px
    }

    for ( let i = 0; i < $elements.length; i += elementsPerRow ) {
      let maxHeight = 0;
      for ( let j = i; j < i + elementsPerRow && j < $elements.length; j++ ) {
        const currentHeight = $( $elements[j] ).outerHeight();
        maxHeight = Math.max( maxHeight, currentHeight );
      }
      for ( let j = i; j < i + elementsPerRow && j < $elements.length; j++ ) {
        $( $elements[j] ).css( 'height', maxHeight + 'px' );
      }
    }
  };

  const handleResize = () => {
    classNames.forEach( ( { className, elementsPerRow } ) => setMaxHeight( className, elementsPerRow ) );
  };

  $window.on( 'resize', debounce( handleResize, 500 ) );

  $window.on( 'load', handleResize );
} );
