( $ => {
  const cloneItems = ( container ) => {
    if ( container.hasClass( '.bs-section-common-vertical-on-scroll' ) ) {
      return;
    }

    container.find( '.bs-div__inner' ).each( ( index, element ) => {
      if ( $( element ).find( '.media-elements' ).length <= 4 ) {
        for ( let i = 0; i < 1; i++ ) {
          $( element ).append( $( element )
            .find( '.media-elements' )
            .clone().addClass( 'cloned-item' ) );
        }
      }
    } );
  };

  const itemsClone = () => {
    const cloneSet = $( '[class*="bs-div--common-vertical-onscroll-"]' );
    cloneItems( cloneSet );
  };

  itemsClone();
} )( jQuery );