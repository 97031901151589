( ( $ ) => {
  const logKey = e => {
    const sectionRect = e.target.getBoundingClientRect();
    const relativeY = e.clientY - sectionRect.top;
    const mouseXpercentage = Math.round( e.clientX );
    const mouseYpercentage = Math.round( relativeY );
    $( '.common-glow > div' ).css( {
      transform:`translate(calc(-50vw + ${mouseXpercentage}px),
      calc(-25vh + ${mouseYpercentage}px))`
    } );
  };
  $( '.bs-section--common-glow' )?.append( `
    <div class="common-glow"><div><div><div><div><div></div></div></div></div></div></div>
  ` );
  $( '.bs-section--common-glow' )?.on( 'mousemove', logKey );
} )( jQuery );