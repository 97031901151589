const anchorLinks = document.querySelectorAll( 'a[href^="#"]' );

anchorLinks.forEach( ( link ) => {
  link.addEventListener( 'click', ( e ) => {
    e.preventDefault();

    const
      targetId = link.href.split( '#' )[1],
      targetElement = document.getElementById( targetId );

    if ( targetElement ) {
      targetElement.scrollIntoView( {
        behavior: 'smooth',
        block: 'start'
      } );
    }
  } );
} );
